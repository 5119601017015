@import '@dashboard-experience/mastodon/lib/theme.scss';

@media (min-width: 991px) {
  html {
    font-size: 14px;
  }
}

body {
  background-color: rgb(247, 249, 251);
}

a {
  color: $checkr-blue;

  &:hover {
    color: $checkr-blue-hover;
  }
}

.card.warning {
  border: 1px solid #F1AC43;
  background-color: #FCF8F2;
}

.card.warning .card-header {
  border-bottom: 1px solid #F1AC43;
  background-color: #FCF8F2;
  color: #E8AD4F;
  font-weight: bold;
}

.card-header {
  font-weight: bold;
}

.header {
  padding: 15px;
  background-color: $white;
  border-bottom: 1px solid #ddd;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.header-blue {
  padding: 15px;
  background-color: $checkr-blue;
  color: $white;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.footer {
  color: #999;
  font-weight: 300;
  font-size: 13px;
  padding: 30px;
  border-top: 1px solid #ddd;
}

.footer-grey {
  background-color: #eaeaea;
}

.footer .checkr-logo {
  margin-bottom: 15px;
}

.footer a {
  text-decoration: underline;
  margin: 0 5px;
}

.footer .language-toggle{
  margin-top: 1rem;
}

.footerLinksContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.footerLink {
  margin: 10px;
}

img.icon {
  width: 20px;
}

.cool-black {
  color: $cool-black-40
}

@media (max-width: 991px) {
  .company-logo {
    height: 40px;
  }
}

@media (min-width: 991px) {
  .company-logo {
    height: 60px;
  }

  .card {
    margin-bottom: 20px !important;
  }

  .card-block {
    padding: 30px;
  }

  .card-header {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.card-block-heading {
  margin-bottom: 1rem;
}

.main-container {
  max-width: 950px;
  margin:    50px auto;
}

.navigation {
  margin-bottom: 30px;
}

.loading {
  width:  60px;
  margin: auto;
  margin-top: 100px;
}

.scrollable {
  max-height: 450px;
  padding:    20px 10px;
  overflow-y: scroll;

  border:        1px solid #ddd;
  border-radius: 3px;
}

.scrollable.expanded {
  max-height: none;
}

.attached-checkbox {
  margin-top: -10px;
}

.alert-full-name {
  color: #31708f;
}

.alert-warning {
  border-color: #e2d69c;
}

/* polyfill for bootstrap 4.0.0 */
.alert-secondary {
  border-color: #d6d8db;
  background-color: #f3f3f3;
  color: #383d41;
}

.alert-panel {
  border-left: 3px solid #FA8D26;
  background-color: #FEEBC8;
  color: #383d41;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) -2px 2px 2px;
}

.alert-panel i {
  color: #FE8F26;
}

.alert-panel .card-header {
  font-weight: bold;
  background-color: transparent;
  border: none;
}

.alert-panel .card-block {
  padding-top: 0;
}

@media only screen and (min-width: 768px) {
  .alert-panel p {
    margin-left: 23px;
  }
}

.card-additional-text {
  color:#999;
  font-weight: 300;
}

.test-banner {
  padding: 10px;
}

.cc-form {
  margin-bottom: 10px;
}

.has-success .form-control-label {
  color: #292b2c;
}

.has-danger .form-control {
  border-color: #d9534f;
}

.has-danger .form-control:active,
.has-danger .form-control:focus {
  border-color: #d9534f;
  box-shadow: 0 0 0 3px rgba(217, 83, 79, 0.25);
}

.has-danger {
  &.middle-name {
    span {
      color: #d9534f;
    }
  }
}

.error-panel {
  margin-top: 50px;
}

.inline-checkbox.top-inline {
  margin-top: -15px;
}
.inline-checkbox.bottom-inline {
  margin-bottom: 0px;
}

.btn.international-address-history-remove-entry {
  padding-right: 0px;
  color: #16161D !important;
}

label {
  color: #333;
  font-weight: 300;
}

.no-bottom-margin label {
  margin-bottom: 0px;
}

@keyframes placeholder-shimmer{
  0%{
      background-position: -468px 0
  }
  100%{
      background-position: 468px 0
  }
}
.panel-loading {
  width: 100%;
  background-color: #eee;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholder-shimmer;
  animation-timing-function: linear;
  background: #f5f5f5;
  background: linear-gradient(to right, #eeeeee 8%, #e5e5e5 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;
}
.panel-loading.loading-location {
  height: 127px;
}
.panel-loading.loading-map {
  height: 400px;
}
.location-panel {
  margin: 0;
  padding: 0;
}
.location-panel.form-group{
  margin-bottom: 0;
}
.location-panel input {
  display: none;
}
.location-panel .card {
  transition: all 0.3s ease-out;
}
.location-panel .card .card-block {
  padding: 20px 10px;
}
.location-panel i.mailbox {
  background: url('./mailbox-black.svg') 99%/20px no-repeat;
  padding: 10px;
}
.location-panel i.fa-bolt, .location-panel i.mailbox {
  margin-right: 0.5rem;
}
.location-panel i.fa-bolt {
  color: #F7B500;
}
.location-panel .name {
  color: black;
  font-weight: bolder;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.location-panel p.location-type span {
  text-transform: none;
}
.location-panel p {
  font-size: 0.9em;
  margin: 0;
  text-transform: uppercase;
  color: #999;
}
.location-panel.selected .name, .location-panel:hover .name,
.location-panel.selected p, .location-panel:hover p,
.location-panel.selected img.icon, .location-panel:hover img.icon {
  color: $white;
}
.location-panel.selected i.mailbox, .location-panel:hover i.mailbox {
  background: url('./mailbox-white.svg') 99%/20px no-repeat;
}


.location-panel .business-hours .days {
  font-weight: bolder;
  margin: 12px 0 0;
}
.location-panel .business-hours .hours {
  margin: 0px;
}

.location-panel.selected .card, .location-panel:hover .card  {
  background-color: $checkr-blue;
  color: $white;
}

.location-selector-map-container {
  overflow: hidden;
}
.location-selector-map .card-block {
  padding: 0;
}
.location-selector-map .control-panel {
  position: absolute;
  bottom: 20px;
  left: 0;
  max-width: 320px;
  background: $white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
  margin: 20px;
  font-size: 13px;
  line-height: 2;
  color: #6b6b76;
  text-transform: uppercase;
  outline: none;
}
.location-selector-map .lab-location {
  padding: 0 8px;
  border-radius: 4px;
  color: $checkr-blue;
  border: 2px solid $checkr-blue;
  background-color: $white;
}

.location-selector-map .lab-location.selected {
  color: $white;
  background-color: $checkr-blue-hover;
}

.mapboxgl-marker {
  z-index: 0;
}

.mapboxgl-marker.selected {
  z-index: 10;
}

.custom-text { cursor: default }
.custom-text h1 { font-size: 1.5rem; }
.custom-text h2 { font-size: 1.25rem; }
.custom-text h3 { font-size: 1rem; }
.custom-text h4 { font-size: 1rem; }
.custom-text h5 { font-size: 1rem; }
.custom-text h6 { font-size: 1rem; }

/* fix the bottom margin for last <p> tags in custom text */
.custom-text > *:last-child,
.card-block > *:last-child,
.alert > *:last-child {
  margin-bottom: 0;
}

/* fix the bottom margin for last row form groups */
@media (min-width: 991px) {
  .card .row:last-child .form-group {
    margin-bottom: 0;
  }
  .card .row:last-child .inline-checkbox.top-inline {
    margin-top: 8px;
  }
}

.signature-pad {
  width: 100%;
}

#dot-authorization ol {
  padding-inline-start: 1em;
}

.btn {
  padding: 10px 30px;
  cursor: pointer;
}

.btn-primary, .btn-primary.disabled, .btn-primary:disabled {
  background-color: $checkr-blue;
  border-color: $checkr-blue;

  &:hover {
    color: $white;
    background-color: $checkr-blue-hover;
    border-color: $checkr-blue-hover;
  }
}

.btn.selected {
  background-color: $checkr-blue;
  color: $white;
}

.form-group {
  margin-bottom: 23px;

  &.form-group-exam-selection {
    .btn {
      padding: 10px;
      font-weight: bold;
    }
  }
}

.form-control::-moz-placeholder {
  font-weight: 300;
  color: #999;
}

.form-control:-ms-input-placeholder {
  font-weight: 300;
  color: #999;
}

.form-control::-webkit-input-placeholder {
  font-weight: 300;
  color: #999;
}

.form-control:active,
.form-control:focus {
  box-shadow: 0 0 0 3px #d2ecff;
  border-color: $checkr-blue;
}

.has-success .form-control:active,
.has-success .form-control:focus {
  box-shadow: 0 0 0 3px #d2ecff;
  border-color: $checkr-blue;
}

.form-control-label {
  margin-bottom: .2rem;
}

.has-success .form-control {
  border: 1px solid rgba(0,0,0,.15);
}

/* for employment end date out-of-range warning */
.has-success .date-warning.form-control {
  border-color: #d9534f;
}
.has-success .date-warning.form-control:active,
.has-success .date-warning.form-control:focus {
  border-color: #d9534f;
  box-shadow: 0 0 0 3px rgba(217, 83, 79, 0.25);
}

.identification-upload .form-group {
  margin: 0
}

.doc-preview {
  margin-right: 10px;
  max-height: 100px;
  border: 1px solid #ccc;
}

ul.bootstrap-typeahead-menu {
  width: 100%;
}

ul.bootstrap-typeahead-menu li > a {
  display: inline-block;
  padding: 5px 15px;
}

ul.bootstrap-typeahead-menu li:hover,
ul.bootstrap-typeahead-menu li.active {
  background-color: #efefef;
}

input[type="text"]:disabled,
input[type="tel"]:disabled,
input[type="email"]:disabled,
select:disabled {
  background: #eceeef url('./lock.svg') 99%/15px no-repeat !important;
  padding-right: 30px;
}

select:disabled {
  background-position: 93%;
}

.add-entry {
  color: $checkr-blue;
  font-weight: bold;
}

.add-entry:hover {
  cursor: pointer;
}

.add-entry > i {
  margin-right: 5px;
}

/* Cant rely on last row child to have a margin */
.btn-remove {
  margin-top: 23px;
}

.btn-remove-docs {
  margin-top: 0;
  margin-bottom: 23px;
  width: 100%;
  vertical-align: top;
  color: #d9534f;

  &:hover {
    color: $white;
    background: #d9534f;
  }
}

.content-spacing {
  margin-top: 16px;
}

.upload-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px;
  overflow: hidden;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
}

.debug-mode .page {
  background: $white;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  padding: 12px;
  border-radius: 3px;
}

.debug-mode .main-container{
  margin-top: 80px;
}
.modal-container, .modal-overlay {
  z-index: 1000;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.modal-overlay { position: absolute; background-color: rgba(0,0,0,.15)}
.modal-content {
  z-index: 1001;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sensitive-label {
  display: flex;
}
.sensitive-toggle {
  color: $checkr-blue;
  cursor: pointer;
  margin-left: .5rem;
}
.sensitive-input {
  letter-spacing: 1px;
}

.hidden {
  display: none;
}

// Typeahead suggestion partial word highlight
mark.rbt-highlight-text {
  padding: 0;   // Remove spacing around highlighted characters within the same word
}

// Health Screening Configuration
#healthScreeningInfo h2 {
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
}

#healthScreeningInfo .examWrapper .card-block {
  background-color: #f7f7f9;

  .exam .card-block {
    background-color: #ffffff;
  }
}

// StandardConsent Configuration
.standard-consent-checkbox strong span{
  padding-right: 5px;
}

// Expungement Configuration
.expungement-consent .card-block .form-group {
  margin-bottom: 0;

  label {
    display: flex;

    input[type="checkbox"] {
      margin: 4px 10px 4px 0;
    }
  }
}

.expungement-header {
  background-color: #002853;
  color: #FFFFFF;
  margin: -15px;
  padding: 20px 0;

  .logo {
    height: 24px;
    width: 100px;
    margin-right: 10px;
  }

  .externalLogo {
    height: 21px;
    margin-top: 3px;
  }

  .circle {
    width: 5px;
    height: 5px;
    background: #F4F4F4;
    border-radius: 50%;
    opacity: 0.47;
    align-self: center;
    margin: 6px 12px 0 2px;
  }

  .title {
    font-size: 26px;
    line-height: 21px;
    font-weight: 200;
  }
}

header .intl-header-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
header .checkr-logo-img {
  height: 60px;
}
header .language-dropdown{
  position: absolute;
  right: 20px;
}
@media screen and (max-width: 768px) {
  header .intl-header-container {
    justify-content: left;
  }
  header .checkr-logo {
    margin-left: 20px;
  }
}
